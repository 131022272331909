import { languageSvc, projectSvc } from "../services";
import {
  ALERT_MESSAGE,
  PROJECT_READ, PROJECT_CREATE, PROJECT_UPDATE, PROJECT_DELETE,
  PROJECT_READ_LANGUAGE, PROJECT_ADD_LANGUAGE, PROJECT_DELETE_LANGUAGE, PROJECT_SET_DEFAULT_LANGUAGE,
  PROJECT_ADD_VERSION, PROJECT_DELETE_VERSION
} from "./types/actions.type";
import {
  PAGE_LOADING, PAGE_SAVING,
  SET_LANGUAGES, SET_PROJECTS, SET_PROJECT_LANGUAGES, SET_PROJECT_VERSIONS
} from "./types/mutations.type";

const state = {
  language_datas: [],
  project_datas: [],
  project_total: 0
};

const getters = {
  language_datas(state) {
    return state.language_datas;
  },
  project_datas(state) {
    return state.project_datas;
  },
  project_total(state) {
    return state.project_total;
  }
};

const actions = {
  [PROJECT_READ]({ commit }, params = { page: 0, limit: 0 }) {
    commit(PAGE_LOADING, true);
    return projectSvc.read(params.page * params.limit + 1, params.limit)
      .then(res => {
        commit(PAGE_LOADING, false);
        if(res.success)
          commit(SET_PROJECTS, res);
        else
          console.log(res.error_message);
      })
      .catch(error => {
        commit(PAGE_LOADING, false);
        console.log(error)
      });
  },
  [PROJECT_CREATE]({ commit, dispatch }, params = { data: {} }) {
    commit(PAGE_SAVING, true);
    return projectSvc.create(params.data)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          dispatch(PROJECT_READ);
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_UPDATE]({ commit, dispatch }, params = { id: 0, data: {} }) {
    commit(PAGE_SAVING, true);
    return projectSvc.update(params.id, params.data)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          dispatch(PROJECT_READ);
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_DELETE]({ commit, dispatch }, params = { id: 0 }) {
    commit(PAGE_SAVING, true);
    return projectSvc.delete(params.id)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          dispatch(PROJECT_READ);
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_READ_LANGUAGE]({ commit }, params = { page: 0, limit: 0 }) {
    return languageSvc.read(params.page * params.limit + 1, params.limit)
      .then(res => {
        if(res.success)
          commit(SET_LANGUAGES, res);
        else
          console.log(res.error_message);
      })
      .catch(error => {
        console.log(error)
      });
  },
  [PROJECT_ADD_LANGUAGE]({ commit, dispatch }, params = { id: 0, newLanguages: [] }) {
    commit(PAGE_SAVING, true);
    return projectSvc.addLanguage(params.id, params.newLanguages)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          commit(SET_PROJECT_LANGUAGES, { id: params.id, data: res.data })
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_DELETE_LANGUAGE]({ commit, dispatch }, params = { id: 0, languageCodes: [] }) {
    commit(PAGE_SAVING, true);
    return projectSvc.deleteLanguage(params.id, params.languageCodes)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          commit(SET_PROJECT_LANGUAGES, { id: params.id, data: res.data })
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_SET_DEFAULT_LANGUAGE]({ commit, dispatch }, params = { id: 0, defaultLanguage: '' }) {
    commit(PAGE_SAVING, true);
    return projectSvc.setDefaultLanguage(params.id, params.defaultLanguage)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          commit(SET_PROJECT_LANGUAGES, { id: params.id, data: res.data })
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_ADD_VERSION]({ commit, dispatch }, params = { id: 0, moduleId: 0, moduleName: '', newVersion: '' }) {
    commit(PAGE_SAVING, true);
    return projectSvc.addVersion(params.id, params.moduleId, params.moduleName, params.newVersion)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          commit(SET_PROJECT_VERSIONS, { id: params.id, data: res.data })
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  },
  [PROJECT_DELETE_VERSION]({ commit, dispatch }, params = { id: 0, moduleId: 0, version: '' }) {
    commit(PAGE_SAVING, true);
    return projectSvc.deleteVersion(params.id, params.moduleId, params.version)
      .then(res => {
        commit(PAGE_SAVING, false);
        if(res.success)
          commit(SET_PROJECT_VERSIONS, { id: params.id, data: res.data })
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  }
};

const mutations = {
  [SET_LANGUAGES](state, { data }) {
    state.language_datas = data;
  },
  [SET_PROJECTS](state, { data, total }) {
    state.project_datas = data;
    state.project_total = total;
  },
  [SET_PROJECT_LANGUAGES](state, { id, data }) {
    const project_data = state.project_datas.find(d => d.Id === id);
    if(project_data)
      project_data.Languages = data;
  },
  [SET_PROJECT_VERSIONS](state, { id, data }) {
    const project_data = state.project_datas.find(d => d.Id === id);
    if(project_data) {
      const module = project_data.Versions.find(m => (m.moduleId || 0) === (data.moduleId || 0) || m.moduleName === data.moduleName);
      if(module) {
        module.moduleId = data.moduleId;
        module.moduleName = data.moduleName;
        module.versions = data.versions;
      }
      else {
        project_data.Versions.splice(0, 0, {
          moduleId: data.moduleId,
          moduleName: data.moduleName,
          versions: data.versions
        })
      }
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
