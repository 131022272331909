import common from "./common.module";
import projectList from "./project-list.module";
import projectDetails from "./project-details.module";

const modules = {
  common,
  projectList,
  projectDetails
}

export default modules;