import apiConfig from '../configs/api'
import baseSvc from './base'

var config = {
  headers: {
    'Authorization' : `Bearer ${apiConfig.token}`
  }
}

const userSvc = {
  create(data) {
    return baseSvc.post('/user/create', data, config).then(res => res.data)
  },
  read(skips, limit) {
    var data = {
      "fields": [ "Username", "Status", "Profile", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ],
      "sorts": [
        {
          "field": "UpdatedDate",
          "type": "desc"
        }
      ]
    }
    return baseSvc.post(`/user/read?includeRoles=true&skips=${skips}&limit=${limit}`, data, config).then(res => res.data)
  },
  get(username) {
    var data = {
      "fields": [ "Username", "Status", "Profile", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ]
    }
    return baseSvc.post(`/user/${username}`, data, config).then(res => res.data)
  },
  delete(username) {
    return baseSvc.post(`/user/${username}/delete`, {}, config).then(res => res.data)
  },
  update(username, data) {
    return baseSvc.post(`/user/${username}/update`, data, config).then(res => res.data)
  },
  changePassword(username, newPassword) {
    return baseSvc.post(`/user/${username}/changepassword`, newPassword, config).then(res => res.data)
  },
  changeStatus(username, newStatus) {
    return baseSvc.post(`/user/${username}/changestatus`, newStatus, config).then(res => res.data)
  }
}

export default userSvc