<template>
    <router-view/>
</template>
  
<script>
    export default {
        name: 'AuthLayout',
        components: {
            
        }
    }
</script>