import apiConfig from '../configs/api'
import baseSvc from './base'

var config = {
  headers: {
    'Authorization' : `Bearer ${apiConfig.token}`
  }
}

const roleSvc = {
  read() {
    var data = {
      "fields": [ "Name", "Description" ],
      "sorts": [
        {
          "field": "Name",
          "type": "asc"
        }
      ]
    }
    return baseSvc.post('/role/read?skips=0&limit=0', data, config).then(res => res.data)
  }
}

export default roleSvc