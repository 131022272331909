export default {
  dataResource: (state) => state.dataResource,
  dataEditedResource: state => state.dataEditedResource,
  currentPage: (state) => state.currentPage,
  totalItems: (state) => state.totalItems,
  dataProject: (state) => state.dataProject,
  versionsList: (state) => state.dataProject.Versions,
  selectedModule: (state) => state.selectedModule,
  selectedVersion: (state) => state.selectedVersion,
  languageCode: (state) => state.languageCode,
  loading: (state) => state.loading,
  saving: state => state.saving,
  dataLanguage: (state) => state.dataLanguage,
  dataModule: (state) => state.dataModule,
  dataCount: (state) => state.dataCount,
  errorMessage: (state) => state.errorMessage,
  errorType: (state) => state.errorType,
  autoAlert: (state) => state.autoAlert,
  searchValue: (state) => state.searchValue,
  pageSize: (state) => state.pageSize,
  resourceType: (state) => state.resourceType,
};
