import axios from 'axios'
import apiConfig from '../configs/api'

export default axios.create({
  baseURL: apiConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'Content-Length,API-Key'
  },
})