<template>
  <div class="main-header shadow-sm">
    <h1 class="mb-0">Resource Translate Portal</h1>
    <div class="right-menu">
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Vietnam Development
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#">Log out</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>

  export default {
    name: 'MainHeader'
  }

</script>

<style>

  .main-header {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 4rem;
    padding: 0 2rem;
    z-index: 10;
  }

  .main-header .right-menu {
    margin-left: auto;
  }

</style>
  