import axios from 'axios'
import apiConfig from '../configs/api'
import { saveAs } from "file-saver";
import { projectSvc, resourceSvc } from "../services";
import { ALERT_MESSAGE, PROJECT_GET, RESOURCE_COUNT, RESOURCE_EXPORT, RESOURCE_IMPORT, RESOURCE_READ, RESOURCE_SAVE } from "./types/actions.type";
import {
  PAGE_EXPORTING, PAGE_IMPORTING, PAGE_LOADING, PAGE_SAVING,
  SET_PROJECT, SET_RESOURCES, SET_RESOURCE_COUNT,
  SET_LANGUAGE, SET_MODULE, SET_VERSION,
  CHECK_EDITED_RESOURCES, CLEAR_EDITED_RESOURCES, SET_PAGE_INDEX, SET_PAGE_SIZE, SET_RESOURCE_TYPE, SET_SEARCH_VALUE
} from "./types/mutations.type";

const state = {
  project_data: {},
  module_data: {},
  is_xcloud: false,
  language: '',
  version: '',
  resource_counts: [],
  resource_datas: [],
  resource_editeds: [],
  resource_total: 0,
  page_index: 0,
  page_size: 25,
  resource_type: 0,
  search_value: ''
};

const getters = {
  project_data(state) {
    return state.project_data;
  },
  module_data(state) {
    return state.module_data;
  },
  is_xcloud(state) {
    return state.is_xcloud;
  },
  language(state) {
    return state.language;
  },
  version(state) {
    return state.version;
  },
  resource_counts(state) {
    return state.resource_counts;
  },
  resource_datas(state) {
    return state.resource_datas;
  },
  resource_editeds(state) {
    return state.resource_editeds;
  },
  resource_total(state) {
    return state.resource_total;
  },
  page_index(state) {
    return state.page_index;
  },
  page_size(state) {
    return state.page_size;
  },
  resource_type(state) {
    return state.resource_type;
  },
  search_value(state) {
    return state.search_value;
  }
};

const actions = {
  [PROJECT_GET]({ commit }, params = { id: 0 }) {
    commit(PAGE_LOADING, true);
    return projectSvc.get(params.id)
      .then(res => {
        commit(PAGE_LOADING, false);
        if(res.success)
          commit(SET_PROJECT, res);
        else
          console.log(res.error_message);
        return res;
      })
      .catch(error => {
        commit(PAGE_LOADING, false);
        console.log(error)
      });
  },
  [RESOURCE_COUNT]({ commit }) {
    commit(PAGE_LOADING, true);
    return resourceSvc.count(
      state.project_data.Id,
      state.module_data.moduleId,
      state.version,
      state.resource_type,
      state.version === 'latest',
      state.search_value
    ).then(res => {
        commit(PAGE_LOADING, false);
        if(res.success)
          commit(SET_RESOURCE_COUNT, res);
        else
          console.log(res.error_message);
      })
      .catch(error => {
        commit(PAGE_LOADING, false);
        console.log(error)
      });
  },
  [RESOURCE_EXPORT]({ commit, dispatch }, params = { type: 0 }) {
    commit(PAGE_EXPORTING, true);
    return resourceSvc.export(
      state.project_data.Id,
      [],
      [{ moduleId: state.module_data.moduleId, version: state.version }]
    ).then(res => {
        if(res.success) {
          const config = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
            responseType: "blob",
          };
          const fileName = `AppResources-${(new Date()).getTime()}.zip`;
          const data = { fileName: fileName, type: params.type || 2, projectType: state.project_data.Type, objects: res.data };
          //console.log(data)
          axios.post(`${apiConfig.converterUrl}export`, JSON.stringify(data), config)
            .then(eRes => {
              commit(PAGE_EXPORTING, false);
              if (state.project_data.Type === 1 && params.type !== 4)
                dispatch(ALERT_MESSAGE, { message: "X-Cloud only support format xml" });
              else {
                saveAs(new Blob([eRes.data]), fileName);
              }
            })
            .catch((err) => {
              commit(PAGE_EXPORTING, false);
              console.log("Export file: ", err);
            });
        }
        else {
          commit(PAGE_EXPORTING, false);
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        }
        return res;
      })
      .catch(error => {
        commit(PAGE_EXPORTING, false);
        console.log(error)
      });
  },
  [RESOURCE_IMPORT]({ commit, dispatch }, data) {
    commit(PAGE_IMPORTING, true);
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };
    return axios.post(`${apiConfig.converterUrl}import`, data, config)
      .then(iRes => {
        console.log(iRes.data);
        if (iRes.data.success) {
          return resourceSvc.import(
            state.project_data.Id,
            state.module_data.moduleId,
            state.version,
            iRes.data.objects
          ).then(res => {
              commit(PAGE_IMPORTING, false);
              if(res.success)
                dispatch(ALERT_MESSAGE, { type: 'success', message: "Import file resources successfully" });
              else
                dispatch(ALERT_MESSAGE, { message: res.error_message });
              return res;
            })
            .catch(error => {
              commit(PAGE_IMPORTING, false);
              console.log(error)
            });
        }
        else {
          commit(PAGE_IMPORTING, false);
          dispatch(ALERT_MESSAGE, { message: iRes.data.message });
          return null;
        }
      })
      .catch(error => {
        commit(PAGE_IMPORTING, false);
        console.log(error)
      });
  },
  [RESOURCE_READ]({ commit }) {
    commit(PAGE_LOADING, true);
    return resourceSvc.read(
      state.project_data.Id,
      state.module_data.moduleId,
      state.version,
      state.language,
      state.resource_type,
      state.version === 'latest',
      state.search_value,
      state.page_index * state.page_size + 1,
      state.page_size
    ).then(res => {
        commit(PAGE_LOADING, false);
        if(res.success)
          commit(SET_RESOURCES, res);
        else
          console.log(res.error_message);
      })
      .catch(error => {
        commit(PAGE_LOADING, false);
        console.log(error)
      });
  },
  [RESOURCE_SAVE]({ commit, dispatch }) {
    commit(PAGE_SAVING, true);
    return resourceSvc.update(
      state.project_data.Id,
      state.module_data.moduleId,
      state.version,
      state.resource_editeds
    ).then(res => {
        commit(PAGE_SAVING, false);
        if(res.success) {
          commit(CLEAR_EDITED_RESOURCES);
          dispatch(ALERT_MESSAGE, { type: 'success', message: res.error_message });
        }
        else
          dispatch(ALERT_MESSAGE, { message: res.error_message });
        return res;
      })
      .catch(error => {
        commit(PAGE_SAVING, false);
        console.log(error)
      });
  }
};

const mutations = {
  [SET_PROJECT](state, { data }) {
    state.project_data = data;
    state.is_xcloud = data.Type === 1;

    if(data.Languages && data.Languages.length > 0) {
      let language = data.Languages.find(l => l.IsDefault);
      if(!language)
        language = data.Languages[0];
      state.language = language.Code;
    }

    if(data.Versions && data.Versions.length > 0) {
      var module_data = data.Versions[0];
      state.module_data = module_data;

      if(module_data.versions && module_data.versions.length > 0)
        state.version = module_data.versions[0].version;
    }
  },
  [SET_RESOURCES](state, { data, total }) {
    if(data && data.length > 0) {
      data = data.map(rs => ({ ...rs, OriginValue: rs.Value }));
      
      const resource_edited = state.resource_editeds.find(rse => rse.LanguageCode === state.language);
      if(resource_edited) {
        resource_edited.Resources.forEach(rse => {
          const resource = data.find(rs => rs.Key === rse.Key);
          if(resource)
            resource.Value = rse.Value;
        });
      }
    }

    if(state.page_index > 0)
      data = [...state.resource_datas, ...data];
    
    state.resource_datas = data;
    state.resource_total = total;
  },
  [SET_RESOURCE_COUNT](state, { data }) {
    state.resource_counts = data;
  },
  [SET_LANGUAGE](state, language) {
    state.language = language;
  },
  [SET_MODULE](state, moduleId) {
    if(state.project_data.Versions && state.project_data.Versions.length > 0) {
      const module = state.project_data.Versions.find(m => (m.moduleId || 0) === (moduleId || 0));
      if(module) {
        state.module_data = module;

        if(module.versions && module.versions.length > 0)
          state.version = module.versions[0].version;
      }
    }
  },
  [SET_VERSION](state, version) {
    state.version = version;
  },
  [SET_PAGE_INDEX](state, pageIndex) {
    state.page_index = pageIndex;
  },
  [SET_PAGE_SIZE](state, pageSize) {
    state.page_size = pageSize;
  },
  [SET_RESOURCE_TYPE](state, resourceType) {
    state.resource_type = resourceType;
  },
  [SET_SEARCH_VALUE](state, searchValue) {
    state.search_value = searchValue;
  },
  [CHECK_EDITED_RESOURCES](state) {
    var rse = [];
    state.resource_datas.forEach(rs => {
      if(rs.Value !== rs.OriginValue)
        rse.push(rs);
    });
    state.resource_editeds[state.language] = rse;

    const resource_edited = state.resource_editeds.find(rse => rse.LanguageCode === state.language);
    if(resource_edited)
      resource_edited.Resources = rse;
    else
      state.resource_editeds.push({ LanguageCode: state.language, Resources: rse });
  },
  [CLEAR_EDITED_RESOURCES](state) {
    state.resource_datas = state.resource_datas.map(rs => ({ ...rs, OriginValue: rs.Value }));
    state.resource_editeds = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
