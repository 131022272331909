
<template>
  <router-view/>
</template>

<script>

  export default {
    name: 'App',
    components: {}
  }

</script>

<style>

  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Nunito:wght@300;400;500;600&display=swap');
  @import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');

  #app {
    font-family:  'Nunito',Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }
  html, body{
    overflow-y: hidden;
  }
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px
  }

  *::-webkit-scrollbar-track {
    background: rgb(61, 61, 60);
    border-radius: 3px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(178, 178, 192);
    border-radius: 20px;
    border: 0;
  }

</style>
