export default {
  dataResource: [],
  dataEditedResource: [],
  currentPage: 0,
  translatedItems: 0,
  totalItems: 0,
  dataProject: {},
  versionsList: [],
  selectedModule: {},
  selectedVersion: "latest",
  languageCode: "",
  loading: {
    language: false,
    resource: false,
    export: false,
    import: false,
  },
  saving: {
    language: false,
    resource: false
  },
  dataLanguage: [],
  dataModule: [],
  dataCount: [],
  errorMessage: "",
  errorType: 'error',
  autoAlert : '',
  searchValue: '',
  pageSize: 25,
  resourceType: 0
};
