<template>
  <div class="card border-0 rounded-0 h-100">
    <div class="card-body d-flex flex-column h-100" style="padding: 1.5rem 2rem 0;">
      <h5 class="card-title mb-3">
        Project List ({{project_total}})
        <button type="button" class="btn btn-primary ms-auto" @click="showOffcanvas()">
          <font-awesome-icon icon="fa-solid fa-plus" class="me-2" />Add New Project
        </button>
      </h5>
      <div class="table-responsive" @scroll="onScrollFixedHeader">
        <table class="table table-centered table-nowrap mb-0 table-project">
          <thead class="table-light">
            <tr class="shadow-sm">
              <th class="ps-3">
                <div class="form-check" style="width: 2rem;">
                  <input type="checkbox" class="form-check-input" id="chk-head">
                  <label class="form-check-label" for="chk-head">&nbsp;</label>
                </div>
              </th>
              <th style="width: 30%;">Project</th>
              <th style="width: 25%;">Languages</th>
              <th style="width: 25%;">Versions</th>
              <th class="pe-3" style="width: 20%;">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="page_loading">
              <td><span class="skeleton">col1</span></td>
              <td><span class="skeleton">col2</span></td>
              <td><span class="skeleton">col3</span></td>
              <td><span class="skeleton">col4</span></td>
              <td><span class="skeleton">col5</span></td>
            </tr>
            <tr v-for="(project, index) in project_datas" :key="index">
              <td class="ps-3">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="chk-item-1">
                  <label class="form-check-label" for="chk-item-1">&nbsp;</label>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <a :href="'project/' + project.Id" class="fw-bold me-2" target="_blank">{{project.Name}}</a>
                    <span v-if="project.Type === 1" class="badge text-bg-primary">X-Cloud</span>
                    <span v-if="project.Type !== 1" class="badge text-bg-secondary">None X-Cloud</span>
                    <span v-if="project.Description" class="badge bg-secondary bg-opacity-25 rounded-circle text-body ms-2" v-tooltip="{placement:'top',customClass:'desc-tooltip',title:project.Description}">
                      <font-awesome-icon icon="fa-solid fa-clipboard" />
                    </span>
                  </div>
                  <span v-if="project.Type === 1" class="sub-text mt-2"><font-awesome-icon icon="fa-solid fa-tablet-screen-button" class="me-2" />{{project.AppId}}</span>
                  <span class="sub-text"><font-awesome-icon icon="fa-solid fa-user" class="me-2" />{{project.UpdatedUsername}}</span>
                  <span class="sub-text"><font-awesome-icon icon="fa-solid fa-calendar-days" class="me-2" />{{project.UpdatedDate}}</span>
                </div>
              </td>
              <td>
                <div class="form-chips p-0" style="min-height: 2rem; margin: -0.25rem;">
                  <span v-for="(language, index) in project.Languages.filter(l => l.IsDefault)" :key="index" class="chip no-icon text-bg-success">{{language.Code}}</span>
                  <span v-for="(language, index) in project.Languages.filter(l => !l.IsDefault)" :key="index" class="chip no-icon">{{language.Code}}</span>
                  <span v-if="project.Languages?.length <= 0" class="chip no-icon">No language(s)</span>
                </div>
              </td>
              <td>
                <template v-for="(module, index) in project.Versions" :key="index">
                  <div class="row">
                    <div class="col-sm-12 mb-2">
                      <span class="fw-bold">{{module.moduleName || 'None'}}</span>
                      <div class="form-chips p-0" style="min-height: 2rem; margin: -0.25rem;">
                        <span v-for="(version, index) in module.versions.filter(v => v.version)" :key="index" class="chip no-icon">
                          {{version.version}}<span v-if="index === 0" class="ms-1">(latest)</span>
                        </span>
                        <span v-if="module.versions.filter(v => v.version)?.length <= 0" class="chip no-icon">No version(s)</span>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="project.Versions?.length <= 0 || (project.Versions?.length === 1 && project.Versions[0].versions.filter(v => v.version)?.length <= 0)" class="form-chips p-0" style="min-height: 2rem; margin: -0.25rem;">
                  <span class="chip no-icon">No version(s)</span>
                </div>
              </td>
              <td class="pe-3">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="badge text-bg-success">Activated</span>
                  <div class="d-flex gap-3">
                    <a :href="'project/' + project.Id" target="_blank"><font-awesome-icon icon="fa-solid fa-circle-info" class="text-info" /></a>
                    <!-- <a v-if="project.Type === 1" :href="'project/x-details/' + project.Id" target="_blank"><font-awesome-icon icon="fa-solid fa-circle-info" class="text-info" /></a>
                    <a v-if="project.Type !== 1" :href="'project/details/' + project.Id" target="_blank"><font-awesome-icon icon="fa-solid fa-circle-info" class="text-info" /></a> -->
                    <a href="#" @click="showOffcanvas(project)"><font-awesome-icon icon="fa-solid fa-pencil" class="text-secondary" /></a>
                    <a href="#" @click="showModalCofirmDelete(project.Id)"><font-awesome-icon icon="fa-solid fa-trash-can" class="text-danger" /></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-end" style="width: 500px; max-width: 100%;" tabindex="-1" id="offcanvasProject">
    <div class="offcanvas-header">
      <h5 v-if="editing" class="offcanvas-title" id="offcanvasRightLabel">Update project<b class="ms-2">#{{project.Id}}</b></h5>
      <h5 v-if="!editing" class="offcanvas-title" id="offcanvasRightLabel">Create new project</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-7 mb-2">
            <label for="project-name" class="col-form-label">Name</label>
            <input type="text" class="form-control form-control-sm" id="project-name" v-model="project.Name" />
          </div>
          <div class="col-sm-5 mb-2">
            <label for="project-type" class="col-form-label">Type</label>
            <select id="project-type" class="form-select form-select-sm" :disabled="editing" v-model="project.Type" @change="onTypeChange">
              <option value="1">X-Cloud</option>
              <option value="2">None X-Cloud</option>
            </select>
          </div>
        </div>
        <div v-if="isXCloud" class="row">
          <div class="col-sm-12 mb-2">
            <label for="project-app-id" class="col-form-label">Application ID</label>
            <input id="project-app-id" type="text" class="form-control form-control-sm" v-model="project.AppId" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mb-2">
            <label for="project-description" class="col-form-label">Description</label>
            <textarea id="project-description" class="form-control form-control-sm" v-model="project.Description"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mb-2">
            <div class="d-flex align-items-center">
              <label class="col-form-label">Languages</label>
              <div v-if="project.Languages?.length > 1" class="dropdown ms-auto">
                <span class="badge text-bg-success rounded-1 px-3 py-2" style="cursor: pointer;" data-bs-toggle="dropdown" :disabled="saving">Set default</span>
                <!-- <button type="button" class="btn btn-success btn-sm" data-bs-toggle="dropdown" :disabled="saving">Set default</button> -->
                <ul class="dropdown-menu dropdown-menu-end shadow">
                  <li v-for="(language, index) in project.Languages" :key="index">
                    <a :class="'dropdown-item' + getDefaultLanguageClass(language.Code)" href="#" @click="setDefaultLanguage(language.Code)">{{language.Code}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-control form-control-sm form-chips form-chips-rlt">
              <span v-for="(language, index) in project.Languages.filter(l => l.IsDefault)" :key="index" class="chip no-icon text-bg-success">{{language.Code}}</span>
              <span v-for="(language, index) in project.Languages.filter(l => !l.IsDefault)" :key="index" class="chip">
                {{language.Code}}
                <font-awesome-icon v-if="!saving" icon="fa-solid fa-xmark" class="chip-icon" @click="DeleteLanguage(language.Code)" />
              </span>
              <span v-if="language_datas?.length <= 0" class="chip">No language(s) found</span>
              <span v-if="!saving && language_datas?.length > 0" class="chip chip-abs no-text text-bg-primary">
                <font-awesome-icon icon="fa-solid fa-plus" class="chip-icon" data-bs-toggle="dropdown" />
                <ul class="dropdown-menu dropdown-menu-end shadow">
                  <li v-for="(language, index) in language_datas" :key="index">
                    <a :class="'dropdown-item' + getAddedLanguageClass(language.Code)" href="#" @click="addNewLanguage(language.Code)">{{language.Code}}</a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div v-if="!isXCloud" class="row">
          <div class="col-sm-12">
            <label class="col-form-label">Versions</label>
            <div class="form-control form-control-sm form-chips form-chips-rlt">
              <span v-for="(version, index) in project.Versions[0]?.versions" :key="index" :class="'chip' + (index === 0 ? '' : ' no-icon')">
                {{version.version}}<span v-if="index === 0" class="ms-1">(latest)</span>
                <font-awesome-icon v-if="!saving && index === 0" icon="fa-solid fa-xmark" class="chip-icon" @click="DeleteVersion(project.Versions[0]?.moduleId, project.Versions[0]?.moduleName, version.version)" />
              </span>
              <span v-if="!saving" class="chip chip-abs no-text text-bg-primary">
                <font-awesome-icon icon="fa-solid fa-plus" class="chip-icon" @click="toggleAddVersion(project.Versions[0]?.moduleName)" />
                <ul :id="(project.Versions[0]?.moduleName || 'none') + '_dropdownAddVersion'" class="dropdown-menu dropdown-menu-end shadow position-absolute top-100 end-0 mt-1">
                  <li><h6 class="dropdown-header">Add new version</h6></li>
                  <li class="px-3 py-1">
                    <input type="text" :id="(project.Versions[0]?.moduleName || 'none') + '_inputAddVersion'" class="form-control form-control-sm" style="width: 12rem;" />
                    <div class="d-flex justify-content-between w-100 mt-2">
                      <button type="button" class="btn btn-outline-secondary btn-sm" @click="toggleAddVersion(project.Versions[0]?.moduleName)">Cancel</button>
                      <button type="button" class="btn btn-primary btn-sm" @click="addNewVersion(project.Versions[0]?.moduleId, project.Versions[0]?.moduleName)">Save</button>
                    </div>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div v-if="isXCloud" class="row">
          <div class="col-sm-12">
            <label class="col-form-label">Versions</label>
            <div class="form-control form-control-sm px-3 py-2 mb-2">
              <div class="d-grid my-2">
                <input type="text" id="inputAddModule" class="form-control form-control-sm mb-2" placeholder="Module" />
                <input type="text" id="inputAddVersion" class="form-control form-control-sm mb-3" placeholder="Version" />
                <button type="button" class="btn btn-primary btn-sm" :disabled="page_saving" @click="addNewModule()">
                  <font-awesome-icon icon="fa-solid fa-plus" class="me-2" />
                  Add
                </button>
              </div>
            </div>
            <div v-if="project.Versions?.length > 0" class="form-control form-control-sm px-3 py-2">
              <div v-for="(module, index) in project.Versions" :key="index" class="row">
                <div class="col-sm-12 mb-2">
                  <label class="col-form-label fw-bold">{{module.moduleName || 'None'}}</label>
                  <div class="form-control form-control-sm form-chips form-chips-rlt">
                    <span v-for="(version, index) in module.versions?.filter(v => v.version)" :key="index" :class="'chip' + (index === 0 ? '' : ' no-icon')">
                      {{version.version}}<span v-if="index === 0" class="ms-1">(latest)</span>
                      <font-awesome-icon v-if="!saving && index === 0" icon="fa-solid fa-xmark" class="chip-icon" @click="DeleteVersion(module.moduleId, module.moduleName, version.version)" />
                    </span>
                    <span v-if="!saving" class="chip chip-abs no-text text-bg-primary">
                      <font-awesome-icon icon="fa-solid fa-plus" class="chip-icon" @click="toggleAddVersion(module.moduleName)" />
                      <ul :id="(module.moduleName || 'none') + '_dropdownAddVersion'" class="dropdown-menu dropdown-menu-end shadow position-absolute bottom-100 end-0 mb-1">
                        <li><h6 class="dropdown-header">Add new version</h6></li>
                        <li class="px-3 py-1">
                          <input type="text" :id="(module.moduleName || 'none') + '_inputAddVersion'" class="form-control form-control-sm" style="width: 12rem;" />
                          <div class="d-flex justify-content-between w-100 mt-2">
                            <button type="button" class="btn btn-outline-secondary btn-sm" @click="toggleAddVersion(module.moduleName)">Cancel</button>
                            <button type="button" class="btn btn-primary btn-sm" @click="addNewVersion(module.moduleId, module.moduleName)">Save</button>
                          </div>
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="offcanvas"><font-awesome-icon icon="fa-solid fa-xmark" class="me-2" />Cancel</button>
      <button type="button" class="btn btn-primary" :disabled="page_saving" @click="saveProject">
        <div v-if="page_saving" class="spinner-grow spinner-grow-sm text-white me-2"></div>
        <font-awesome-icon v-if="!page_saving" icon="fa-solid fa-floppy-disk" class="me-2" />
        Save
      </button>
    </div>
  </div>

  <div class="modal fade" id="modalConfirmDeleteProject" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Delete project</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure, you want to delete this project?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"><font-awesome-icon icon="fa-solid fa-xmark" class="me-2" />No</button>
          <button type="button" class="btn btn-primary" @click="deleteProject"><font-awesome-icon icon="fa-solid fa-check" class="me-2" />Yes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapActions, mapGetters } from "vuex";
  import { Offcanvas, Modal } from 'bootstrap';

  export default {
    name: 'ProjectList',
    components: { },
    props: {
      itemsPerPage: { type: Number, required: false, default: 10 }
    },
    data() {
      return {
        currentPage: 0,
        editing: false,
        isXCloud: true,
        project: { Id: 0, Name: '', Type: 1, Description: '', AppId: '', Languages: [], Versions: [] },
        offcanvasProject: null, modalConfirmDeleteProject: null
      };
    },
    computed: {
      config() {
        return {
          page: this.currentPage,
          limit: this.itemsPerPage
        };
      },
      pages() {
        if (this.page_loading || this.total <= this.itemsPerPage)
          return [];
        return [
          ...Array(Math.ceil(this.total / this.itemsPerPage)).keys()
        ].map(e => e + 1);
      },
      ...mapGetters(["page_loading", "page_saving", "language_datas", "project_datas", "project_total"])
    },
    watch: {
      currentPage() {
        this.loadProjects();
      }
    },
    mounted() {
      this.loadProjects();
      this.loadLanguages();

      const offcanvasProjectElm = document.getElementById('offcanvasProject');
      this.offcanvasProject = new Offcanvas(offcanvasProjectElm, {});

      const modalConfirmDeleteProjectElm = document.getElementById('modalConfirmDeleteProject');
      this.modalConfirmDeleteProject = new Modal(modalConfirmDeleteProjectElm, {});
    },
    methods: {
      ...mapActions([
        "projectRead", "projectCreate", "projectUpdate", "projectDelete", "projectReadLanguage",
        "projectAddLanguage", "projectDeleteLanguage", "projectSetDefaultLanguage",
        "projectAddVersion", "projectDeleteVersion", "projectSetDefaultVersion"
      ]),
      loadLanguages() {
        this.projectReadLanguage();
      },
      loadProjects() {
        this.projectRead(this.config);
      },
      deleteProject() {
        this.projectDelete({ id: this.project.Id })
          .then(() => { this.modalConfirmDeleteProject.hide(); });
      },
      saveProject() {
        const data = {
          Name: this.project.Name,
          Type: this.project.Type,
          Description: this.project.Description,
          AppId: this.project.AppId
        };

        if(this.editing)
          this.projectUpdate({ id: this.project.Id, data: data })
            .then(res => {
              if (res && res.success)
                this.offcanvasProject.hide();
            });
        else {
          if(this.project.Languages && this.project.Languages.length > 0) {
            data.Languages = [];
            this.project.Languages.reverse().forEach(language => {
              if(language.IsDefault)
                data.LanguageDefault = language.Code;
              data.Languages.push(language.Code);
            })
          }

          if(this.project.Versions && this.project.Versions.length > 0) {
            data.Versions = [];
            this.project.Versions.forEach(module => {
              if(module.versions?.length > 0) {
                module.versions.reverse().forEach(version => {
                  data.Versions.push({ ModuleId: module.moduleId, ModuleName: module.moduleName, Version: version.version });
                });
              }
              else
                data.Versions.push({ ModuleId: module.moduleId, ModuleName: module.moduleName, Version: '' });
            })
          }

          this.projectCreate({ data: data })
            .then(res => {
              if (res && res.success)
                this.offcanvasProject.hide();
            });
        }
      },
      onScrollFixedHeader(evt) {
        evt.target.querySelector('thead').style.transform = `translateY(${evt.target.scrollTop}px)`;
      },
      onTypeChange(evt) {
        this.isXCloud = evt.target.value === '1';
        this.project.AppId = '';
        this.project.Versions = [];
      },
      showOffcanvas(data) {
        if (data) {
          this.editing = true;
          this.project = { Id: data.Id, Name: data.Name, Type: data.Type, Description: data.Description, AppId: data.AppId, Languages: data.Languages, Versions: data.Versions };
        }
        else {
          this.editing = false;
          this.project = { Id: 0, Name: '', Type: 1, Description: '', AppId: '', Languages: [], Versions: [] };
        }
        this.isXCloud = this.project.Type === 1;

        this.offcanvasProject.show();
      },
      showModalCofirmDelete(id) {
        this.project.Id = id;
        this.modalConfirmDeleteProject.show();
      },

      // Project languages
      addNewLanguage: function(languageCode) {
        const language = this.project.Languages.find(l => l.Code === languageCode);
        if(language)
          return;

        if(this.editing)
          this.projectAddLanguage({ id: this.project.Id, newLanguages: [languageCode] }).then(this.updateChangedLanguages);
        else
          this.project.Languages.splice(0, 0, { Code: languageCode, IsDefault: this.project.Languages.length <= 0 });
      },
      DeleteLanguage: function(languageCode) {
        if(this.editing)
          this.projectDeleteLanguage({ id: this.project.Id, languageCodes: [languageCode] }).then(this.updateChangedLanguages);
        else
          this.project.Languages = this.project.Languages.filter(l => l.Code !== languageCode);
      },
      setDefaultLanguage: function(languageCode) {
        const language = this.project.Languages.find(l => l.Code === languageCode && l.IsDefault);
        if(language)
          return;

        if(this.editing)
          this.projectSetDefaultLanguage({ id: this.project.Id, defaultLanguage: languageCode }).then(this.updateChangedLanguages);
        else {
          const defaultLanguage = this.project.Languages.find(l => l.IsDefault);
          if(defaultLanguage)
            defaultLanguage.IsDefault = false;

          const language = this.project.Languages.find(l => l.Code === languageCode);
          if(language)
            language.IsDefault = true;
        }
      },
      getAddedLanguageClass(languageCode) {
        const language = this.project.Languages.find(l => l.Code === languageCode);
        return language ? ' disabled' : '';
      },
      getDefaultLanguageClass(languageCode) {
        const language = this.project.Languages.find(l => l.Code === languageCode && l.IsDefault);
        return language ? ' disabled' : '';
      },
      updateChangedLanguages(res) {
        if (res && res.success)
          this.project.Languages = res.data;
      },

      // Project versions
      addNewModule: function() {
        const inputAddModule = document.getElementById('inputAddModule');
        const inputAddVersion = document.getElementById('inputAddVersion');
        if((!inputAddModule || !inputAddModule.value) && (!inputAddVersion || !inputAddVersion.value))
          return;
        
        let module = this.project.Versions.find(m => m.moduleName === inputAddModule.value);
        if(module) {
          if(inputAddVersion && inputAddVersion.value) {
            const version = module.versions.find(v => v.version === inputAddVersion.value);
            if(version) {
              inputAddModule.value = '';
              inputAddVersion.value = '';
              return;
            }
          }
        }
        else {
          module = { moduleId: 0, moduleName: inputAddModule.value, versions: [] };
          this.project.Versions.splice(0, 0, module);
        }

        if(this.editing)
          this.projectAddVersion({ id: this.project.Id, moduleId: module.moduleId, moduleName: module.moduleName, newVersion: inputAddVersion.value }).then(this.updateChangedVersion);
        else {
          if(inputAddVersion && inputAddVersion.value)
            module.versions.splice(0, 0, { version: inputAddVersion.value });
        }

        inputAddModule.value = '';
        inputAddVersion.value = '';
      },
      addNewVersion: function(moduleId, moduleName) {
        let module = this.project.Versions.find(m => m.moduleName === moduleName);
        if(!module) {
          module = { moduleId: moduleId, moduleName: moduleName, versions: [] };
          this.project.Versions.splice(0, 0, module);
        }

        const inputAddVersion = document.getElementById((moduleName || 'none') + '_inputAddVersion');
        if(!inputAddVersion || !inputAddVersion.value)
          return;

        const version = module.versions.find(v => v.version === inputAddVersion.value);
        if(version)
          return;
        
        if(this.editing)
          this.projectAddVersion({ id: this.project.Id, moduleId: moduleId, moduleName: moduleName, newVersion: inputAddVersion.value }).then(this.updateChangedVersion);
        else
          module.versions.splice(0, 0, { version: inputAddVersion.value });

        this.toggleAddVersion(moduleName);

        inputAddVersion.value = '';
      },
      DeleteVersion: function(moduleId, moduleName, version) {
        const module = this.project.Versions.find(m => m.moduleName === moduleName);
        if(!module)
          return;

        if(this.editing)
          this.projectDeleteVersion({ id: this.project.Id, moduleId: moduleId, version: version }).then(this.updateChangedVersion);
        else
          module.versions = module.versions.filter(v => v.version !== version);
      },
      toggleAddVersion(moduleName) {
        const dropdownAddVersion = document.getElementById((moduleName || 'none') + '_dropdownAddVersion');
        if(dropdownAddVersion.classList.contains('d-block'))
          dropdownAddVersion.classList.remove('d-block');
        else {
          document.querySelectorAll('.dropdown-menu.d-block').forEach(dd => {
            dd.classList.remove('d-block');
          });
          dropdownAddVersion.classList.add('d-block');
        }
      },
      updateChangedVersion(res) {
        if (res && res.success) {
          const module = this.project.Versions.find(m => (m.moduleId || 0) === (res.data.moduleId || 0) || m.moduleName === res.data.moduleName);
          if(module) {
            module.moduleId = res.data.moduleId;
            module.moduleName = res.data.moduleName;
            module.versions = res.data.versions;
          }
          else {
            this.project.Versions.splice(0, 0, {
              moduleId: res.data.moduleId,
              moduleName: res.data.moduleName,
              versions: res.data.versions
            })
          }
        }
      }
    }
  }

</script>

<style>

  .desc-tooltip .tooltip-inner {
    max-width: 20rem;
    text-align: left;
  }

  table.table-project > tbody td {
    padding: 1rem 0.5rem;
  }

</style>