// Common
export const ALERT_MESSAGE = "alertMessage";

// Auth
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const CHECK_AUTH = "checkAuth";

// Project list
export const PROJECT_READ = "projectRead";
export const PROJECT_CREATE = "projectCreate";
export const PROJECT_UPDATE = "projectUpdate";
export const PROJECT_DELETE = "projectDelete";
export const PROJECT_READ_LANGUAGE = "projectReadLanguage";
export const PROJECT_ADD_LANGUAGE = "projectAddLanguage";
export const PROJECT_DELETE_LANGUAGE = "projectDeleteLanguage";
export const PROJECT_SET_DEFAULT_LANGUAGE = "projectSetDefaultLanguage";
export const PROJECT_ADD_VERSION = "projectAddVersion";
export const PROJECT_DELETE_VERSION = "projectDeleteVersion";

// Project detail
export const PROJECT_GET = "projectGet";
export const RESOURCE_COUNT = "resourceCount";
export const RESOURCE_EXPORT = "resourceExport";
export const RESOURCE_IMPORT = "resourceImport";
export const RESOURCE_READ = "resourceRead";
export const RESOURCE_SAVE = "resourceSave";

export const ARTICLE_PUBLISH = "publishArticle";
export const ARTICLE_DELETE = "deleteArticle";
export const ARTICLE_EDIT = "editArticle";
export const ARTICLE_EDIT_ADD_TAG = "addTagToArticle";
export const ARTICLE_EDIT_REMOVE_TAG = "removeTagFromArticle";
export const ARTICLE_RESET_STATE = "resetArticleState";

export const COMMENT_CREATE = "createComment";
export const COMMENT_DESTROY = "destroyComment";
export const FAVORITE_ADD = "addFavorite";
export const FAVORITE_REMOVE = "removeFavorite";
export const FETCH_ARTICLE = "fetchArticle";
export const FETCH_ARTICLES = "fetchArticles";
export const FETCH_COMMENTS = "fetchComments";
export const FETCH_PROFILE = "fetchProfile";
export const FETCH_PROFILE_FOLLOW = "fetchProfileFollow";
export const FETCH_PROFILE_UNFOLLOW = "fetchProfileUnfollow";
export const FETCH_TAGS = "fetchTags";

export const UPDATE_USER = "updateUser";
