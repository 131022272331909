import apiConfig from '../configs/api'
import baseSvc from './base'

var config = {
  headers: {
    'Authorization' : `Bearer ${apiConfig.token}`
  }
}

const projectSvc = {
  create(data) {
    return baseSvc.post('/project/create', data, config).then(res => res.data)
  },
  read(skips, limit) {
    var data = {
      "fields": [ "Id", "AppId", "Name", "Type", "Description", "Status", "Config", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ],
      // "matches": [
      //   {
      //     "field": "$arr:Name,Description",
      //     "value": "Test",
      //     "type": "$lw"
      //   }
      // ],
      "sorts": [
        {
          "field": "UpdatedDate",
          "type": "desc"
        }
      ]
    }
    return baseSvc.post(`/project/read?includeLanguages=true&includeVersions=true&skips=${skips}&limit=${limit}`, data, config).then(res => res.data)
  },
  get(id) {
    var data = {
      "fields": [ "Id", "AppId", "Name", "Type", "Description", "Status", "Config", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ]
    }
    return baseSvc.post(`/project/${id}`, data, config).then(res => res.data)
  },
  delete(id) {
    return baseSvc.post(`/project/${id}/delete`, {}, config).then(res => res.data)
  },
  update(id, data) {
    return baseSvc.post(`/project/${id}/update`, data, config).then(res => res.data)
  },
  changeStatus(id, newStatus) {
    return baseSvc.post(`/project/${id}/changestatus`, newStatus, config).then(res => res.data)
  },
  getLanguage(id) {
    return baseSvc.get(`/project/${id}/language`, config).then(res => res.data)
  },
  addLanguage(id, newLanguages) {
    return baseSvc.post(`/project/${id}/language/add`, newLanguages, config).then(res => res.data)
  },
  deleteLanguage(id, languageCodes) {
    return baseSvc.post(`/project/${id}/language/delete`, languageCodes, config).then(res => res.data)
  },
  setDefaultLanguage(id, defaultLanguage) {
    return baseSvc.post(`/project/${id}/language/set_default`, defaultLanguage, config).then(res => res.data)
  },
  getVersion(id) {
    return baseSvc.get(`/project/${id}/version`, config).then(res => res.data)
  },
  addVersion(id, moduleId, moduleName, version) {
    var data = { moduleId, moduleName, version };
    return baseSvc.post(`/project/${id}/version/add`, data, config).then(res => res.data)
  },
  deleteVersion(id, moduleId, version) {
    var data = { moduleId, version };
    return baseSvc.post(`/project/${id}/version/delete`, data, config).then(res => res.data)
  }
}

export default projectSvc