import apiConfig from '../configs/api'
import baseSvc from './base'

export var controller = new AbortController()

var config = {
  headers: {
    'Authorization' : `Bearer ${apiConfig.token}`
  },
  signal: controller.signal
}

const resourceSvc = {
  import(projectId, moduleId, version, datas) {
    const data = { projectId, moduleId, version, resources: datas };
    return baseSvc.post(`/resource/import`, data, config).then(res => res.data)
  },
  export(projectId, languages, moduleVersions) {
    const data = { projectId, languageCodes: languages, moduleVersions };
    return baseSvc.post(`/resource/export`, data, config).then(res => res.data)
  },
  update(projectId, moduleId, version, datas) {
    const data = { projectId, moduleId, version, languageResources: datas };
    return baseSvc.post(`/resource/update`, data, config).then(res => res.data)
  },
  count(projectId, moduleId, version, resourceType, readAllKey, searchValue) {
    const data = { projectId, moduleId, version: version || 'none', filter: {  resourceType, readAllKey, searchValue } }
    return baseSvc.post(`/resource/count`, data, config).then(res => res.data)
  },
  read(projectId, moduleId, version, language, resourceType, readAllKey, searchValue, skips, limit) {
    const data = { projectId, moduleId, version: version || 'none', languageCode: language, filter: {  resourceType, readAllKey, searchValue } }
    return baseSvc.post(`/resource/read?skips=${skips}&limit=${limit}`, data, config).then(res => res.data);
  }
}

export default resourceSvc