<template>
  <h1>Forgot password</h1>
</template>

<script>
export default {
    name: 'ForgotPassword'
}
</script>

<style>

</style>