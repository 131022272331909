import apiConfig from '../configs/api'
import baseSvc from './base'

var config = {
  headers: {
    'Authorization' : `Bearer ${apiConfig.token}`
  }
}

const moduleSvc = {
  create(data) {
    return baseSvc.post('/module/create', data, config).then(res => res.data)
  },
  read(skips, limit) {
    var data = {
      "fields": [ "Id", "Name", "Description", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ],
      "sorts": [
        {
          "field": "UpdatedDate",
          "type": "desc"
        }
      ]
    }
    return baseSvc.post(`/module/read?skips=${skips}&limit=${limit}`, data, config).then(res => res.data)
  },
  get(id) {
    var data = {
      "fields": [ "Id", "Name", "Description", "CreatedDate", "UpdatedDate" ],
      "includes": [
        {
          "table": "CreatedUser",
          "fields": ["CreatedUsername"]
        },
        {
          "table": "UpdatedUser",
          "fields": [ "UpdatedUsername" ]
        }
      ]
    }
    return baseSvc.post(`/module/${id}`, data, config).then(res => res.data)
  },
  delete(id) {
    return baseSvc.post(`/module/${id}/delete`, {}, config).then(res => res.data)
  },
  update(id, data) {
    return baseSvc.post(`/module/${id}/update`, data, config).then(res => res.data)
  }
}

export default moduleSvc