import {
    projectSvc,
    resourceSvc,
    languageSvc,
    moduleSvc
} from "../services";
export default {
    async getDataResource({
        commit,
        getters
    }, needCancel = false) {
        commit("TOGGLE_RESOURCE_LOADING");
        const skips = getters['pageSize'] * getters['currentPage'] + 1;
        let totalPage = null;
        if (getters['totalItems'] > 0) {
            totalPage = Math.floor(getters['totalItems'] / getters['pageSize']) + 1;
        }
        const isEndOfPagination = getters['currentPage'] > 0 && getters['currentPage'] >= totalPage
        if (isEndOfPagination) {
            commit("TOGGLE_RESOURCE_LOADING");
            return
        }
        return await resourceSvc
            .read(
                getters["dataProject"]["Id"] || 1001,
                getters['selectedModule']["moduleId"],
                getters["selectedVersion"],
                getters["languageCode"],
                getters["resourceType"],
                getters["selectedVersion"] === 'latest',
                getters["searchValue"],
                skips,
                getters["pageSize"],
                needCancel
            )
            .then((res) => {
                if (res.success) {
                    let loadedResource = [];
                    if (getters['currentPage'] > 0) {
                        loadedResource = [...getters['dataResource'], ...res['data']]
                    } else {
                        loadedResource = [...res['data']]
                    }
                    let resources = [...loadedResource];
                    if (resources.length > 0) {
                        resources = resources.map((p) => ({
                            ...p,
                            OriginValue: p.Value,
                            isEdited: false,
                        }));
                        let editedResource = getters['dataEditedResource'].find(
                            (x) => x['languageCode'] === getters['languageCode']
                        );

                        if (editedResource !== undefined) {
                            editedResource['resources'].forEach((item) => {
                                let resource = resources.find((x) => x['Key'] === item['Key']);
                                if (resource !== undefined) {
                                    resource['Value'] = item['Value'];
                                    resource["isEdited"] = true;
                                }
                            });
                        }
                    }

                    commit("SET_NOT_TRANSLATE_ITEMS", res['totalTranslated'] || 0);
                    commit("SET_TOTAL_ITEMS", res['total'] || 0);
                    commit("SET_DATA_RESOURCE", resources);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
                commit("TOGGLE_RESOURCE_LOADING");
            })
            .catch((err) => {
                commit("TOGGLE_RESOURCE_LOADING");
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    getDataCount({
        commit,
        getters
    }) {
        return resourceSvc
            .count(
                getters["dataProject"]["Id"] || 1001,
                getters['selectedModule']["moduleId"],
                getters["selectedVersion"],
                getters["resourceType"],
                getters["selectedVersion"] === 'latest',
                getters["searchValue"]
            )
            .then((res) => {
                if (res['success']) {
                    commit("SET_DATA_COUNT", [...res['data']]);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
            })
            .catch((err) => {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    getDataProject({
        commit,
        getters
    }) {
        projectSvc.get(getters["dataProject"]["Id"] || 1001).then((res) => {
            if (res.success) {
                const project = {
                    ...res['data'],
                };
                commit("SET_PROJECT_RESOURCE", project);
                commit("SET_VERSIONS_LIST", project['Versions']);
                commit(
                    "SET_LANGUAGE_CODE",
                    project['Languages'].find((lang) => lang['IsDefault'])["Code"] ??
                    project['Languages'][0]["Code"]
                );

                if(project['Versions'] && project['Versions'].length > 0) {
                    var defaultVersion = project['Versions'][0];
                    commit("SET_SELECTED_MODULE", defaultVersion.moduleId);
                    commit("SET_SELECTED_VERSION", defaultVersion.versions[0].version);
                }
            } else {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
            }
        });
    },
    getDataLanguage({
        commit
    }) {
        commit("TOGGLE_LANGUAGE_LOADING");
        languageSvc
            .read(1, 0)
            .then((res) => {
                if (res.success) {
                    commit("SET_LANGUAGE_RESOURCE", res.data);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
                commit("TOGGLE_LANGUAGE_LOADING");
            })
            .catch((err) => {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    getDataModule({
        commit
    }) {
        moduleSvc
            .read(1, 0)
            .then((res) => {
                if (res.success) {
                    commit("SET_MODULE_RESOURCE", res.data);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
            })
            .catch((err) => {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    processNewModule({
        commit,
        getters
    }, newModuleId) {
        return projectSvc
            .addVersion(
                getters['dataProject']['Id'] || 1001,
                newModuleId,
                ''
            )
            .then((res) => {
                if (res.success) {
                    commit("ADD_NEW_MODULE", { moduleId: res.data.moduleId, moduleName: res.data.moduleName, versions: [] });
                    commit("SET_SELECTED_MODULE", res.data.moduleId);
                    commit("SET_SELECTED_VERSION", '');
                    commit("SET_DATA_RESOURCE", []);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
                return res;
            })
            .catch((err) => {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    processNewVersion({
        commit,
        getters
    }, newVersion) {
        return projectSvc
            .addVersion(
                getters['dataProject']['Id'] || 1001,
                getters['selectedModule']["moduleId"],
                newVersion
            )
            .then((res) => {
                if (res.success) {
                    commit("SET_SELECTED_VERSION", newVersion);
                    commit("ADD_NEW_VERSION", { version: res.data.version, createdDate: res.data.createdDate });
                    commit("SET_DATA_RESOURCE", []);
                } else {
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                    commit('TRANSMIT_API_ERROR_TYPE', 'error')
                    commit('TRANSMIT_API_ERROR_MESSAGE', res['error_message'])
                }
                return res;
            })
            .catch((err) => {
                commit('TRANSMIT_API_AUTO_ALERT', Math.random())
                commit('TRANSMIT_API_ERROR_TYPE', 'error')
                commit('TRANSMIT_API_ERROR_MESSAGE', err)
            });
    },
    onSaveResource({ commit, getters, dispatch }) {
        commit('TOGGLE_RESOURCE_SAVING')
        resourceSvc
            .update(
                getters['dataProject']['Id'] || 1001,
                getters['selectedModule']["moduleId"],
                getters['selectedVersion'],
                getters['dataEditedResource']
            )
            .then(res => {
                commit('TOGGLE_RESOURCE_SAVING')
                if (res.success) {
                    if (getters['resourceType'] === 0) {
                        dispatch('updateResource');
                    } else {
                        commit('SET_DATA_RESOURCE', []);
                        dispatch('getDataResource');
                    }

                    if (getters['resourceType'] !== 2) {
                        dispatch('getDataCount');
                    }

                    commit('SET_DATA_EDITED_RESOURCE', []);
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random());
                    commit('TRANSMIT_API_ERROR_TYPE', "success");
                    commit('TRANSMIT_API_ERROR_MESSAGE', 'Save resources successful!');
                }
            })
            .catch(err => {
                commit('TOGGLE_RESOURCE_SAVING')
                console.error(err)
            })
    },
    onSelectLanguage({ commit, getters, dispatch }, language) {
        if (language === getters['languageCode'])
            return
        
        setTimeout(async () => {
            commit('SET_CURRENT_PAGE', 0)
            commit('SET_DATA_RESOURCE', [])
            commit('SET_TOTAL_ITEMS', 0)
            commit('SET_LANGUAGE_CODE', language)

            await dispatch('getDataResource')
        }, 200)
    },
    updateResource({ commit, getters }) {
        let editedResource = getters['dataEditedResource'].find(x => x['languageCode'] === getters['languageCode']);
        let resources = getters['dataResource'];

        if (editedResource !== undefined) {
            editedResource.resources.forEach(item => {
                let resource = resources.find(x => x['Key'] === item['Key']);
                if (resource !== undefined) {
                    resource['OriginValue'] = resource['Value'] = item['Value'];
                    resource['isEdited'] = false;
                }
            });
        }
        //set for tab lang default
        const $defaultLangTab = document.getElementById(`${getters['languageCode']}-tab`)
        let langDefault = $defaultLangTab.getAttribute('data-default') || 'false';
        if (langDefault === 'true') {
            resources = resources.map(p => p.Value !== '' ? ({ ...p, 'DefaultValue': p.Value }) : p);
        } else {//set DefaultValue for tab lang not default
            const $langTab = document.querySelectorAll('[data-default=true]')
            let lang = $langTab[0]['dataset']['languageCode'];
            let editedResource = getters['dataEditedResource'].find(x => x['languageCode'] === lang);
            if (editedResource !== undefined) {
                editedResource.resources.forEach(item => {
                    let resource = resources.find(x => x['Key'] === item['Key']);
                    if (resource !== undefined) {
                        resource['DefaultValue'] = item['Value'];
                    }
                });
            }
        }
        commit('SET_DATA_RESOURCE', resources);
        commit('SET_DATA_EDITED_RESOURCE', [])
        const $elements = document.querySelectorAll('table > tbody > tr');
        [].forEach.call($elements, function (el) {
            el.classList = '';
        });
    },
    async onAddedNewLanguage({ commit, getters, dispatch }, newLanguage) {
        return await projectSvc.addLanguage(getters['dataProject']['Id'] || 1001, newLanguage)
            .then(res => {
                commit('TOGGLE_LANGUAGE_SAVING')
                if (res.success) {
                    getters['dataProject']['Languages'] = res['data'] || []
                    dispatch('getDataCount');
                    commit('TRANSMIT_API_AUTO_ALERT', Math.random());
                    commit('TRANSMIT_API_ERROR_TYPE', "success");
                    commit('TRANSMIT_API_ERROR_MESSAGE', 'Selected language has been added successfully!');
                }
            })
            .catch(err => {
                commit('TOGGLE_LANGUAGE_SAVING')
                console.error(err)
            })
    }
};