<template>
  <div id="toast" :class="`toast align-items-center text-bg-${bgColor} border-0`" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <font-awesome-icon v-if="errorType == 'error'" icon="fa-solid fa-circle-exclamation" class="m-auto ms-2" />
      <font-awesome-icon v-if="errorType == 'success'" icon="fa-solid fa-circle-check" class="m-auto ms-2" />
      <div class="toast-body text-start" name="alert-message"  ref="alertMessage"  :style="{ width: '100%' }">{{errorMessage}}</div>
      <button type="button" class="btn-close btn-close-white m-auto mx-2" data-bs-dismiss="toast" aria-label="Close"></button>
      <div style="display:none">{{autoAlert}}</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { Toast } from 'bootstrap';

  export default {
    name: 'AlertMessage',
    computed: {
      ...mapState(['autoAlert', 'errorType', 'errorMessage'])
    },
    data() {
      return {
        bgColor: this.errorType !== 'error' ? 'success' : 'error',
      }
    },
    methods: {
      ...mapMutations(['TRANSMIT_API_ERROR_MESSAGE']),
      show() {
        if (this.errorMessage && this.errorMessage.length > 0) {
          this.getBackgroundColor(this.errorType);
          setTimeout(() => {
            let toast = new Toast(document.getElementById('toast'));
            toast.show({ delay: 1000 });          
          }, 0);
        }
      },
      getBackgroundColor(type) {
        const color = new Map([['error', 'danger'],['success', 'success']]);
        this.bgColor = color.get(type) ?? 'info';
      },
    },
    updated() {
        this.show();
    }
  }
</script>