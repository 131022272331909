// Common
export const PAGE_EXPORTING = "pageExporting";
export const PAGE_IMPORTING = "pageImporting";
export const PAGE_LOADING = "pageLoading";
export const PAGE_SAVING = "pageSaving";

// Auth
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";

// Project
export const SET_LANGUAGES = "setLanguages";
export const SET_PROJECTS = "setProjects";
export const SET_PROJECT_LANGUAGES = "setProjectLanguages";
export const SET_PROJECT_VERSIONS = "setProjectVersions";

// Project detail
export const SET_PROJECT = "setProject";
export const SET_RESOURCES = "setResources";
export const SET_RESOURCE_COUNT = "setResourceCount";
export const SET_LANGUAGE = "setLanguage";
export const SET_MODULE = "setModule";
export const SET_VERSION = "setVersion";
export const SET_PAGE_INDEX = "setPageIndex";
export const SET_PAGE_SIZE = "setPageSize";
export const SET_RESOURCE_TYPE = "setResourceType";
export const SET_SEARCH_VALUE = "setSearchValue";
export const CHECK_EDITED_RESOURCES = "checkEditedResources";
export const CLEAR_EDITED_RESOURCES = "clearEditedResources";

export const SET_ARTICLE = "setArticle";

export const SET_COMMENTS = "setComments";

export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_ARTICLE_IN_LIST = "updateArticleInList";
export const RESET_STATE = "resetModuleState";
