import { createApp } from 'vue'
import axios from 'axios';
import Vuex from 'vuex'
import VueAxios from 'vue-axios';
import router from './router'
import storeConfigs from './store'
import modules from './modules'
import App from './App.vue'
import { Tooltip } from 'bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

storeConfigs.modules = modules;
const store = new Vuex.Store(storeConfigs);

const app = createApp(App)
app.use(VueAxios, axios).use(router).use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.directive('full-size', (elm, binding) => {
  if(elm.tagName)
  new Tooltip(elm, binding.value)
})
app.directive('tooltip', (elm, binding) => {
  new Tooltip(elm, binding.value)
})
app.mount('#app')