export default {
  SET_DATA_RESOURCE(state, dataResource) {
    state.dataResource = [...dataResource];
  },
  SET_DATA_EDITED_RESOURCE(state, dataEditedResource) {
    state.dataEditedResource = [...dataEditedResource];
  },
  SET_CURRENT_PAGE(state, pageNumber) {
    state.currentPage = +pageNumber;
  },
  SET_NOT_TRANSLATE_ITEMS(state, number) {
    state.translatedItems = +number;
  },
  SET_TOTAL_ITEMS(state, total) {
    state.totalItems = total;
  },
  SET_PROJECT_RESOURCE(state, dataProject) {
    state.dataProject = { ...dataProject };
  },
  SET_SELECTED_MODULE(state, moduleId) {
    const module = state.dataProject.Versions.find(v => (v.moduleId || 0) === (moduleId || 0));
    if(module)
      state.selectedModule = module;
  },
  SET_SELECTED_VERSION(state, version) {
    state.selectedVersion = version;
  },
  SET_VERSIONS_LIST(state, versions) {
    state.versionsList = versions;
  },
  ADD_NEW_MODULE(state, newModule) {
    state.dataProject.Versions = [...[newModule], ...state.dataProject.Versions];
    state.versionsList = state.dataProject.Versions;
  },
  ADD_NEW_VERSION(state, newVersion) {
    const module = state.dataProject.Versions.find(v => (v.moduleId || 0) === (state.selectedModule.moduleId || 0));
    if(module)
      module.versions = [...[newVersion], ...module.versions];
    state.versionsList = state.dataProject.Versions;
  },
  TOGGLE_LANGUAGE_LOADING(state) {
    state.loading.language = !state.loading.language;
  },
  TOGGLE_LANGUAGE_SAVING(state) {
    state.saving.language = !state.saving.language;
  },
  TOGGLE_RESOURCE_LOADING(state) {
    state.loading.resource = !state.loading.resource;
  },
  TOGGLE_RESOURCE_SAVING(state) {
    state.saving.resource = !state.saving.resource;
  },
  TOGGLE_EXPORT_LOADING(state) {
    state.loading.export = !state.loading.export;
  },
  TOGGLE_IMPORT_LOADING(state) {
    state.loading.import = !state.loading.import;
  },
  SET_LANGUAGE_RESOURCE(state, langs) {
    state.dataLanguage = [...langs];
  },
  SET_MODULE_RESOURCE(state, modules) {
    state.dataModule = [...modules];
  },
  SET_LANGUAGE_CODE(state, languageCode) {
    state.languageCode = languageCode;
  },
  SET_DATA_COUNT(state, dataCount) {
    state.dataCount = [...dataCount];
  },
  TRANSMIT_API_ERROR_MESSAGE(state, msg) {
    state.errorMessage = msg;
  },
  TRANSMIT_API_ERROR_TYPE(state, type) {
    state.errorType = type
  },
  TRANSMIT_API_AUTO_ALERT(state, alert) {
    state.autoAlert = alert
  },
  CHANGE_INIT_STATE(state) {
    state.isInitialized = !state.isInitialized;
  },
  SET_SEARCH_VALUE(state, key){
    state.searchValue = key
  },
  SET_PAGE_SIZE(state, size){
    state.pageSize = size
    state.currentPage = 0
  },
  SET_RESOURCE_TYPE(state, type){
    state.resourceType = type
  }
};
