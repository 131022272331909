import { createRouter, createWebHistory } from 'vue-router'

import AuthLayout from '../layouts/auth.layout.vue'
import MainLayout from '../layouts/main.layout.vue'

// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'

import ProjectList from '../views/ProjectList.vue'
import ProjectDetails from '../views/ProjectDetails.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/project'
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          component: LoginView
        },
        {
          path: 'forgot-password',
          component: ForgotPasswordView
        }
      ]
    },
    {
      path: '/project',
      component: MainLayout,
      children: [
        {
          path: '',
          component: ProjectList
        },
        {
          path: ':id',
          component: ProjectDetails
        }
      ]
    },
    // {
    //   path: '/project/:id',
    //   component: HomeView
    // },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

export default router
