<template>
  <h1>Login</h1>
</template>

<script>
export default {
    name: 'LoginPage'
}
</script>

<style>

</style>