import { ALERT_MESSAGE } from "./types/actions.type";
import { PAGE_EXPORTING, PAGE_IMPORTING, PAGE_LOADING, PAGE_SAVING } from "./types/mutations.type";

const state = {
  page_exporting: false,
  page_importing: false,
  page_loading: false,
  page_saving: false
};

const getters = {
  page_exporting(state) {
    return state.page_exporting;
  },
  page_importing(state) {
    return state.page_importing;
  },
  page_loading(state) {
    return state.page_loading;
  },
  page_saving(state) {
    return state.page_saving;
  }
};

const actions = {
  [ALERT_MESSAGE]({ commit }, params = { type: '', message: '' }) {
    commit('TRANSMIT_API_AUTO_ALERT', Math.random());
    commit('TRANSMIT_API_ERROR_TYPE', params.type || 'error');
    commit('TRANSMIT_API_ERROR_MESSAGE', params.message);
  }
};

const mutations = {
  [PAGE_EXPORTING](state, value) {
    state.page_exporting = value;
  },
  [PAGE_IMPORTING](state, value) {
    state.page_importing = value;
  },
  [PAGE_LOADING](state, value) {
    state.page_loading = value;
  },
  [PAGE_SAVING](state, value) {
    state.page_saving = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
